import nonNullable from '@orgnc/core/lib/utils/types/nonNullable';
import { IBlock } from '@orgnc/core/lib/wordpress/api/fragments/blocksFragment';

// eslint-disable-next-line import/prefer-default-export
export const filterCoreBlocks = (blocks: IBlock[]) => {
  const supportedInlinePostBlocks = [
    'core/html',
    'core/paragraph',
    'core/image',
    'core/gallery',
    'core/heading',
    'core/list',
    'core/embed',
    'core/video',
    'core/audio',
    'core/pullquote',
    'core/quote',
    'core/separator',
  ];
  return blocks
    .filter(nonNullable)
    .filter((b) => supportedInlinePostBlocks.includes(b.name));
};
