
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/domains/kicks99.com/shows/[slug]",
      function () {
        return require("private-next-pages/domains/kicks99.com/shows/[slug]/index.page.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/domains/kicks99.com/shows/[slug]"])
      });
    }
  